import BaseApi from '../BaseApi';

class AuthApi extends BaseApi {

  login = (login, password) => this.sendQuery(
    this.queryTypes.POST,
    '/api/admin/login',
    { login, password }
  )

  check = token => this.sendQuery(
    this.queryTypes.GET,
    '/api/admin/check',
    { token }
  )

  logout = () => this.sendQuery(
    this.queryTypes.GET,
    '/api/admin/logout',
  )
}

export default AuthApi;