import React from 'react';
import block from 'bem-cn';
import { Link } from 'react-router-dom';

import './Button.scss';

const Button = ({
    callBack = f => f, // Button callback
    text = '', // Button text
    color = 'dark', // Button color
    size = 'default', // Type of size: default, container 
    isDisabled = false, // Disabled
    isUppercase = false, // Button text is upper
    isBold = false, // Button text is bold
    fontSize = 'normal', // Texts size: small, normal, large
    isVertical = false, // Button is vertical
    isSquare = false, // Square corners
    link, // Link for button. If link isn't undefined, callback don't work
  }) => {
  const b = block('button');
  
  const button = <button
    className={b({ color, size: link ? 'container' : size, isUppercase, isBold, fontSize, isVertical, isDisabled, isSquare })}
    onClick={callBack}
    disabled={isDisabled}>
    {isVertical ? text.split('').join(' ') : text}
  </button>;

  return link ? <Link to={link} className={b('layout', { size })}>
    {button}
  </Link> : button
}

export default Button;

