import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import { actions } from '../';

import { languages } from 'services/locale';

import './Locale.scss';

const Locale = () => {
  const b = block('locale');
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { lang } = useSelector(state => state.locale);

  const changeLang = useCallback(value => dispatch(actions.changeLang(value)), [dispatch]);

  const items = Object.keys(languages).map(t => <div key={t} className={b('item', { active: lang === t })} onClick={() => changeLang(t)}>
    {t.toUpperCase()}
  </div>)

  return <section className={b({ isOpen })} onClick={() => setOpen(!isOpen)}>
    {isOpen ? items : lang.toUpperCase()}
  </section>
}

export default Locale;
