const betsStatuses = {
  1: 'won',
  2: 'lost',
}

const transfersTypes = {
  1: 'bet',
  2: 'won',
  3: 'return',
  5: 'jackpotWin',
}

const clientsSelector = auth => auth.clients.reduce((obj, t) => ({ ...obj, [t.id]: `${t.id}_${t.company_name}` }), {} )

export { betsStatuses, transfersTypes, clientsSelector }