import { actionTypes } from './actions';
import { initialState } from './initial';

export function reducer(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CHANGE_LANG:
      return {
        ...state,
        locale: payload.locale,
        lang: payload.lang,
      };

    default:
      return { ...state };
  }
}