import React, { useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';
import { Lines } from 'react-preloaders';

import { Auth, actions as authActions } from './features/Auth';
import { Admin } from './features/Admin';

import Notify from 'features/Notify/view';

import './App.scss';

const App = () => {
  const b = block('app');
  const { isAuth, isLoadingApp } = useSelector(state => state.auth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.check());
  }, [dispatch])

  return (
    <React.Fragment>
      <Lines color="#FF8D00" background="#13171D" customLoading={isLoadingApp} />
      <article className={b()}>
        <Notify />
        {!isLoadingApp ? isAuth ? <React.Fragment>
          <Admin />
        </React.Fragment> :
          <Auth /> : null}
      </article>
    </React.Fragment>
  );
}

export default App;
