const conversionAdditionals = data => {
  const result = {};
  ['sessions', 'firework_bets', 'new_users'].forEach(t => {
    let count = 0;
    Object.values(data).forEach(tempValue => {
      count += tempValue[t];
    })
    result[t] = count;
  })
  return result;
}

export { conversionAdditionals };