import { actions } from '../';

const gamesChangedKeys = {
  is_active: data => actions.setActiveGame(data)
}

const clientGamesChangedKeys = {
  is_active: data => actions.setActiveClientGame(data)
}

export { gamesChangedKeys, clientGamesChangedKeys };