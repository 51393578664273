export const conversionFialds = [
  {
    name: 'sessions',
    color: '#5f3c11',
  },
  {
    name: 'firework_bets',
    color: '#FF8D00',
  },
  {
    name: 'new_users',
    color: '#4e4438',
  },
  {
    name: 'spin_bets',
    color: '#FF8D00',
  },
]