import { actions } from '../';

const gamesGlobalButtons = [
  {
    textIdent: 'turnOffAll',
    func: () => actions.switchAllGames(false),
  },
  {
    textIdent: 'turnOnAll',
    func: () => actions.switchAllGames(true),
  }
]

const clientGamesGlobalButtons = [
  {
    textIdent: 'turnOffAll',
    func: () => actions.switchAllClientGames(false),
  },
  {
    textIdent: 'turnOnAll',
    func: () => actions.switchAllClientGames(true),
  }
]

export { gamesGlobalButtons, clientGamesGlobalButtons };