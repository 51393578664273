import initialState from './initial';
import { actionTypes } from './actions';

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GET_DATA:
      return {
        ...state,
        data: payload,
      }

    case actionTypes.GET_CONVERSION:
      const result = {};
      Object.entries(payload).forEach(t => {
        const date = t[0].split('-').reverse().join('-');
        result[date] = t[1];
      })
      return {
        ...state,
        data: result,
      }

    case actionTypes.SET_IS_PROCESS:
      return {
        ...state,
        isProcess: payload,
      }

    case actionTypes.CLEAR_DATA:
      return {
        ...initialState
      }

    default: return { ...state };
  }
}

export default reducer;