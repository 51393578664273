export const locale = {
  successCreated: 'Created!',
  successRemoved: 'Removed!',
  successEdit: 'Edited!',
  successSetToken: 'Token set successfully',
  chooseClient: 'Choose client',

  //auth
  successAuth: 'Authorization was successful',
  login: 'Login',
  password: 'Password',
  logIn: 'Log in',
  singIn: 'Sign in',

  //header
  role: 'Role',
  roles: {
    0: 'Client',
    1: 'Root',
  },
  name: 'Name',

  //filters
  from: 'From',
  to: 'To',
  currency: 'Currency',
  playerId: 'Player id',
  roundId: 'Round id',
  clientId: 'Client id',
  status: 'Status',
  get: 'Get',
  won: 'Won',
  lost: 'Lost',
  notCalc: 'Not paid',
  notSelected: 'Select...',
  page: 'Page',
  isUTC0: 'isUTC0',

  //buttons
  thisMonth: 'This month',
  lastMonth: 'Last month',
  today: 'Today',
  yesterday: 'Yesterday',
  save: 'Save',
  create: 'Create',
  setToken: 'Set token',
  turnOffAll: 'Turn off all',
  turnOnAll: 'Turn on all',
  ganerate: 'Generate',

  //titles
  id: 'Id',
  client_id: 'Client id',
  round_id: 'Round id',
  game_id: 'Round id',
  user_id: 'Player id',
  req_id: 'Bet id ',
  remote_user_id: 'Remote player id',
  result_number: 'Result',
  chance_percent: 'Chance',
  choice: 'Choice',
  created_at: 'Date',
  started_at: 'Date',
  transfer_id: 'Transfer id',
  bet_amount: 'Amount',
  win_amount: 'Win amount',
  success_send: 'Sended money',
  coefficient: 'Odd',
  properties: 'Properties',
  bet_limits: 'Limits',
  settings: 'Settings',
  auth_token: 'Auth token',
  company_name: 'Name',
  seamless_url: 'Url',
  company_site: 'Site',
  client: 'Client',
  confirmation: 'Confirmation',
  from_currency: 'From currency',
  to_currency: 'To currency',
  rate: 'Rate',
  hashes: 'Hashes',
  dino: 'Dino',
  plinko: 'Plinko',
  dice: 'Dice',
  secret_key: 'Secret key',
  url_ending: 'URL ending',
  balls_count: 'Balls count',
  pyramid_height: 'Pyramid height',
  risk: 'Risk',
  keno: 'Keno',
  picked_cells: 'Picked cells',
  generated_cells: 'Generated cells',
  head_company_name: 'Head Company',
  cards: 'Cards',
  picked_outcomes: 'Selected outcomes',
  // req_id: 'req Id',
  win_transfer_id: 'Win transfer Id',
  royalty_coefficient: 'Royalty Coefficient',

  //Table
  selects: {
    status: {
      2: 'Lost',
      1: 'Won',
    }
  },

  //Novigation
  bets: 'Bets',
  hilo: 'HiLo',
  conversion: 'Conversion',
  games: 'Games',
  firecrash: 'Firecrash',
  clients: 'Clients',
  transfers: 'Transfers',
  currencies: 'Currencies',
  spin2win: 'Spin2Win',
  currencyRates: 'Currency rates',
  gamesSettings: 'Games Settings',
  penalty: 'Penalty',
  glassBridge: 'Glass Bridge',
  cookies: 'Cookies',
  CoinFlip: 'CoinFlip',


  // Additional Info
  sessions: 'Sessions',
  new_users: 'New users',
  played_users: 'Played users',
  bets_count: 'Bets count',
  count: 'Count',
  total_bet: 'Total bet money',
  total_win: 'Total win money',
  total: 'Profit',
  royalty: 'Royalty (USD)',
  total_bet_by_currency: 'Total bet by currency',
  total_win_by_currency: 'Total win by currency',
  total_by_currency: 'Total by currency',

  // Graphics
  firework_bets: 'Firecrash bets',
  spin_bets: 'Spin2Win bets',

  // Confirmation
  areYouSure: 'Are you sure?',
  cancel: 'Cancel',
  limitsError: 'Invalid limits value. Numbers must contain no more than one decimal place.',
}