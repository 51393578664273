export default {
  isAuth: false,
  userId: null,
  userName: '',
  role: null,
  platform: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop',
  isProcess: false,
  isLoadingApp: true,
  clients: [],
  actuallyClientId: null,
  onlyClientID: null,
};