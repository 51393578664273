import BaseApi from '../BaseApi';

class AdminApi extends BaseApi {

  getByUrl = ({
    url,
    filters,
  }) => {
    return this.sendQuery(
      filters ? this.queryTypes.POST : this.queryTypes.GET,
      url,
      filters,
    );
  }

  deleteByUrl = ({
    url,
    id
  }) => this.sendQuery(
    this.queryTypes.DELETE,
    url,
    { id },
  )

  getTransfers = filters => this.sendQuery(
    this.queryTypes.POST,
    '/api/admin/transfers',
    filters,
  )

  getCurrencies = clientId => this.sendQuery(
    this.queryTypes.GET,
    `/api/admin/client-currencies/${clientId}`,
  )

  getClientGames = (id) => this.sendQuery(
    this.queryTypes.GET,
    `/api/admin/clients_games?client_id=${id}`,
  )

  setActiveGame = game_name => this.sendQuery(
    this.queryTypes.PATCH,
    `/api/admin/properties/switch_is_active/${game_name}`,
  )

  switchAllGames = is_active => this.sendQuery(
    this.queryTypes.PATCH,
    `/api/admin/properties/switch_all_games`,
    { is_active }
  )

  editGame = (game, name) => this.sendQuery(
    this.queryTypes.PUT,
    `/api/admin/properties/${name}`,
    game,
  )

  createClient = fields => this.sendQuery(
    this.queryTypes.POST,
    '/api/admin/create-new-client',
    fields,
  )

  editClient = (client, id) => this.sendQuery(
    this.queryTypes.POST,
    `/api/admin/update-client/${id}`,
    client,
  )

  setToken = client_id => this.sendQuery(
    this.queryTypes.POST,
    '/api/admin/set-token',
    { client_id },
  )

  createCurrency = (fields, clientId) => this.sendQuery(
    this.queryTypes.POST,
    `/api/admin/client-currencies/${clientId}`,
    fields,
  )

  createCurrencyRate = fields => this.sendQuery(
    this.queryTypes.POST,
    '/api/admin/set-currency-rate',
    fields,
  )

  editCurrencyRate = (rate, id) => this.sendQuery(
    this.queryTypes.PUT,
    `/api/admin/set-currency-rate`,
    { ...rate, id },
  )

  genHashes = name => this.sendQuery(
    this.queryTypes.GET,
    `/api/admin/generate_hashes/${name}`,
  )

  setActiveClientGame = (game_name, clientId) => this.sendQuery(
    this.queryTypes.PATCH,
    `/api/admin/clients_games/${clientId}/switch_is_active/${game_name}`,
  )

  switchAllClientGames = (is_active, clientId) => this.sendQuery(
    this.queryTypes.PATCH,
    `/api/admin/clients_games/${clientId}/switch_all_games`,
    { is_active }
  )

  editClientGame = (game, gameName, clientId) => this.sendQuery(
    this.queryTypes.PUT,
    `/api/admin/clients_games/${clientId}/${gameName}`,
    game,
  )
}

export default AdminApi;