import AuthApi from './AuthApi';
import AdminApi from './AdminApi';

class Api {
  constructor() {
    this.auth = new AuthApi();
    this.admin = new AdminApi();
  }
}

export default Api;
