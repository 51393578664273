import { addNotify } from 'features/Notify';

const actionTypes = {
 LOGIN_SUCCESS: 'LOGIN_SUCCESS',
 LOGOUT: 'LOGOUT',
 SET_IS_PROCESS: 'auth/SET_IS_PROCESS',
 GET_CLIENTS: 'auth/GET_CLIENTS',
 SET_ACTUALLY_CLIENT_ID: 'auth/SET_ACTUALLY_CLIENT_ID',
 SET_ONLY_CLIENT_ID: 'auth/SET_ONLY_CLIENT_ID',
}

const login = (login, password) => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.auth.login(login, password);
  
  if (response.success) {
    if (response.data.role === 1) {
      await dispatch(getClients());
    }
    dispatch(addNotify(locale.successAuth, 'success'))
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: response.data })
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const check = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  const token = getState().auth;
  const response = await api.auth.check(token);

  if (response.success) {
    if (response.data.role === 1) {
      await dispatch(getClients());
    }
    dispatch({ type: actionTypes.LOGIN_SUCCESS, payload: response.data })
  } else {
    dispatch({ type: actionTypes.LOGOUT });
  }
}

const getClients = () => async (dispatch, getState, extra) => {
  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: true });
  const { api } = extra;
  const { locale } = getState().locale;
  const response = await api.admin.getByUrl({ url: '/api/admin/clients' });
  
  if (response.success) {
    dispatch({ type: actionTypes.GET_CLIENTS, payload: response.data })
    if (response.data.clients.length === 1) dispatch(setOnlyClientId(response.data.clients[0].id))
  } else {
    dispatch(addNotify(locale.errorMessages[response.codeStatus.toString()] || locale.errorMessages.defaultMessage, 'error'))
  }

  dispatch({ type: actionTypes.SET_IS_PROCESS, payload: false });
}

const logout = () => async (dispatch, getState, extra) => {
  const { api } = extra;
  await api.auth.logout();
  dispatch({ type: actionTypes.LOGOUT });
}

const setActuallyClientId = id => async dispatch => dispatch({ type: actionTypes.SET_ACTUALLY_CLIENT_ID, payload: id });

const setOnlyClientId = id => async dispatch => dispatch({ type: actionTypes.SET_ONLY_CLIENT_ID, payload: id });

export {
  actionTypes,
  login,
  check,
  logout,
  getClients,
  setActuallyClientId,
  setOnlyClientId,
}
