import dayjs from 'dayjs';

import { betsStatuses, transfersTypes, clientsSelector } from  './selectors';
import { inputTypes } from './inputTypes';

//Filters must be specified in the same format { value: any, type: on of inputTypes, priority: number, textIdent: 'string', options: object (not require) }

const hiloBetsFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
  player_id: {
    value: null,
    type: inputTypes.number,
    priority: 3,
    textIdent: 'playerId',
  },
  remote_user_id: {
    value: null,
    type: inputTypes.number,
    priority: 3.1,
    textIdent: 'remote_user_id',
  },
  round_id: {
    value: null,
    type: inputTypes.number,
    priority: 4,
    textIdent: 'roundId',
  },
  status: {
    value: null,
    type: inputTypes.select,
    options: betsStatuses,
    priority: 6,
    textIdent: 'status',
  },
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
    accessRoles: [1],
  }
}

const firecrashBetsFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
  player_id: {
    value: null,
    type: inputTypes.number,
    priority: 3,
    textIdent: 'playerId',
  },
  remote_user_id: {
    value: null,
    type: inputTypes.number,
    priority: 3.1,
    textIdent: 'remote_user_id',
  },
  round_id: {
    value: null,
    type: inputTypes.number,
    priority: 4,
    textIdent: 'roundId',
  },
  status: {
    value: null,
    type: inputTypes.select,
    options: betsStatuses,
    priority: 6,
    textIdent: 'status',
  },
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
    accessRoles: [1],
  }
}

const glassBridgeFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
  remote_user_id: {
    value: null,
    type: inputTypes.number,
    priority: 3.1,
    textIdent: 'remote_user_id',
  },
  game_id: {
    value: null,
    type: inputTypes.number,
    priority: 4,
    textIdent: 'roundId',
  },
  status: {
    value: null,
    type: inputTypes.select,
    options: betsStatuses,
    priority: 6,
    textIdent: 'status',
  },
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
    accessRoles: [1],
  }
}

const conversionFilters = {
  from_date: {
    value: dayjs().add(-1, 'week').format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
}

const transfersFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
  remote_user_id: {
    value: null,
    type: inputTypes.number,
    priority: 2,
    textIdent: 'remote_user_id',
  },
  note: {
    value: null,
    type: inputTypes.string,
    priority: 3,
    textIdent: 'note',
  },
  type: {
    value: null,
    type: inputTypes.select,
    options: transfersTypes,
    priority: 7,
    textIdent: 'type',
  },
  id: {
    value: null,
    type: inputTypes.number,
    priority: 5,
    textIdent: 'id',
  },
  req_id: {
    value: null,
    type: inputTypes.string,
    priority: 6,
    textIdent: 'req_id',
  },
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 4,
    textIdent: 'client',
    accessRoles: [1],
  },
  isUTC0: {
    value: false,
    type: inputTypes.checkbox,
    priority: 8,
    textIdent: 'isUTC0'
  }
}

const spinFilters = {
  from_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 0,
    textIdent: 'from',
  },
  to_date: {
    value: dayjs().format('YYYY-MM-DD'),
    type: inputTypes.date,
    priority: 1,
    textIdent: 'to',
  },
  // user_id: {
  //   value: null,
  //   type: inputTypes.number,
  //   priority: 2,
  //   textIdent: 'userId',
  // },
  remote_user_id: {
    value: null,
    type: inputTypes.number,
    priority: 3.1,
    textIdent: 'remote_user_id',
  },
  type: {
    value: null,
    type: inputTypes.select,
    options: transfersTypes,
    priority: 6,
    textIdent: 'type',
  },
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
    accessRoles: [1],
  }
}

const currenciesFilters = {
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
  }
}

const clientGamesFilters = {
  client_id: {
    value: null,
    type: inputTypes.select,
    optionsFromRedux: clientsSelector,
    priority: 1,
    textIdent: 'client',
    accessRoles: [1],
  }
}

export {
  hiloBetsFilters,
  firecrashBetsFilters,
  conversionFilters,
  transfersFilters,
  spinFilters,
  currenciesFilters,
  clientGamesFilters,
  glassBridgeFilters,
};