import React, { useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';

import { actions as authActions } from 'features/Auth';

import exitSvg from './img/exit.svg';


import './Header.scss';

const Header = () => {
  const b = block('header');
  const { locale } = useSelector(state => state.locale);
  const { userName, role, userId } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const logout = useCallback(() => dispatch(authActions.logout()), [dispatch])

  return <header className={b()}>
    <div className={b('logo')}>
      <div className={b('text')}>
        <div>Admin</div>
        <div>Panel</div>
      </div>
      FIABLE      
    </div>
    <div className={b('info')}>
      <div className={b('name')}>
        {`${locale.name}: ${userName}`}
      </div>
      <div className={b('role')}>
        {`${locale.role}: ${locale.roles[role]?.toLowerCase()}; id: ${userId}`}
      </div>
    </div>
    <SVGInline svg={exitSvg} className={b('exit').toString()} onClick={logout}/>
  </header>
}

export default Header;