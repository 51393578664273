import React from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import Select from 'react-select'

import CheckBox from 'components/CheckBox';

import './Input.scss';

const Input = (
  {
    value,
    name,
    callBack,
    placeholder = '',
    size = 'default',
    type = 'text',
    isRequired,
    options = [],
    notNeedDefaultValue = false,
    ...props
  }) => {
  const { locale } = useSelector(state => state.locale);
  const b = block('input');
  // Checking for an input or selector
  return type === 'select' ?
    <Select
      onChange={opt => callBack(opt.value)}
      styles={customStyles}
      options={
        !notNeedDefaultValue ? [
          { value: null, label: locale.notSelected },
          ...Object.entries(options).map(t => ({ value: t[0], label: locale[t[1]] ? locale[t[1]] : t[1] }))
        ] : Object.entries(options).map(t => ({ value: t[0], label: locale[t[1]] ? locale[t[1]] : t[1] }))}
    /> : type === 'checkbox' ? 
    <CheckBox value={value} changeValue={callBack} /> :
    <input
      type={type}
      name={name}
      value={value}
      placeholder={placeholder || ''}
      onChange={e => callBack(e.currentTarget.value)}
      className={b({ sizable: size, type })}
      required={isRequired}
      {...props}
    />
};

//Styles for selector
const customStyles = {
  menu: (provided, state) => ({
    ...provided,
    color: '#FFFFFFF',
    background: '#2A3040',
  }),

  control: (provided, state) => ({
    ...provided,
    color: '#FF8D00',
    background: 'transparent',
    borderColor: '#FFFFFF',
    borderRadius: '0',
    maxHeight: '35px',
    minHeight: '35px',
  }),

  singleValue: (provided, state) => ({
    ...provided,
    color: '#FFFFFF',
  }),

  dropdownIndicator: (provided, state) => ({
    ...provided,
    background: 'transparent',
  }),

  option: (provided, state) => ({
    ...provided,
    background: state.isSelected ? '#FF8D00' : '#2A3040',
  }),
}

export default Input;