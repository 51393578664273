import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import block from 'bem-cn';

import { getValueFromData } from 'features/Admin/data/inputTypes';

import Input from 'components/Input';
import Button from 'components/Button';

import './Create.scss';
import { getDataFromValue } from 'features/Admin/data/inputTypes';

const b = block('create');

const Create = ({ titles = {}, title = '', callback = f => f }) => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);

  const [isOpen, setOpen] = useState(false);
  const [data, changeData] = useState(Object.entries(titles)
    .reduce((result, t) => t[1].isCreatable ? { ...result, [t[0]]: { type: t[1].type, value: t[1]?.defaultValue ?? '' }} : result, {}));

  const stop = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, [])

  const changeSpecificData = useCallback((key, value) => changeData({ ...data, [key]:{ ...data[key], value } }), [data])

  const fields = Object.entries(data).map(tempField => {
    const value = getValueFromData(tempField[1].value, tempField[1].type)
    return <div key={tempField[0]} className={b('item')}>
      <div className={b('name')}>
        {locale[tempField[0]]}
      </div>
      <Input
        name={tempField[0]}
        type={tempField[1].type}
        value={value}
        callBack={value => changeSpecificData(tempField[0], value)}
      />
    </div>
  })

  const onSubmit = useCallback(() => {
    dispatch(callback({
      fields: Object.entries(data).reduce((result, t) => ({ ...result, [t[0]]: getDataFromValue(t[1].value, t[1].type) }), {}),
      callback: () => setOpen(false)}))
  }, [callback, data, dispatch])

  return isOpen ?
    <div className={b('wrapper')} onClick={() => setOpen(false)}>
      <article className={b()} onClick={stop}>
        <h2 className={b('title')}>{`${locale.create} ${title}`}</h2>
          {fields}
          <div className={b('button-container')}>
            <Button callBack={onSubmit} text={locale.create} color="dark-fill" size="container" isSquare  fontSize="small" />
          </div>
      </article>
    </div>
    : <div className={b('add-button')} onClick={() => setOpen(true)}>
    +
  </div>
}

export default Create;