import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux'

import Input from 'components/Input';
import Button from 'components/Button';
import { actions } from '../';
import authBackImg from './img/authBack.png';

import './Auth.scss';

const Auth = () => {
  const b = block('auth');
  const [userName, changeUserName] = useState('');
  const [password, passwordUserName] = useState('');
  const locale = useSelector(state => state.locale.locale);
  const { isProcess } = useSelector(state => state.auth);
  const dispatch = useDispatch();
  const signIn = useCallback(() => dispatch(actions.login(userName, password)), [userName, password, dispatch])

  return <article className={b()}>
    <img src={authBackImg} className={b('back')} alt="" />
    <form className={b('container')} onSubmit={signIn}>
      <div className={b('title')}>{locale.singIn}</div>
      <div className={b('input-block')}>
        <div className={b('label')}>{locale.login}</div>
        <Input value={userName} callBack={value => changeUserName(value)} name="email" />
      </div>
      <div className={b('input-block')}>
        <div className={b('label')}>{locale.password}</div>
        <Input value={password} callBack={value => passwordUserName(value)} type="password" name="password" />
      </div>
      <div className={b('button')}>
        <Button type="submit" text={locale.logIn} callBack={signIn} size='container' color='dark-fill' isDisabled={isProcess} />
      </div>
    </form>
  </article>
}

export default Auth;
