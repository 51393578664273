import initialState from './initial';
import { actionTypes } from './actions';

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        isAuth: true,
        userName: payload.login,
        userId: payload.client_id,
        role: payload.role,
        isLoadingApp: false,
      }

    case actionTypes.SET_IS_PROCESS:
      return {
        ...state,
        isProcess: payload,
      }

    case actionTypes.LOGOUT:
      return {
        ...initialState,
        isLoadingApp: false,
      }

    case actionTypes.GET_CLIENTS:
      return {
        ...state,
        clients: payload.clients,
      }
    
    case actionTypes.SET_ACTUALLY_CLIENT_ID:
      return {
        ...state,
        actuallyClientId: payload,
      }
     
    case actionTypes.SET_ONLY_CLIENT_ID:
      return {
        ...state,
        onlyClientID: payload,
      }  

    case actionTypes.SET_PLATFORM:
      return { ...state, platform: (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? 'mobile' : 'desktop'}

    default: return { ...state };
  }
}

export default reducer;