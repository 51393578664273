import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import { GridLoader } from 'react-spinners';
import { Switch, Route, Redirect } from 'react-router-dom';

import Header from 'components/Header';
import Navigation from 'components/Navigation';
import { Locale } from 'features/Locale';
import { pages } from '../data/pages';
import Page from './Page';

import './Admin.scss';

const Admin = () => {
  const b = block('admin');
  const { isProcess } = useSelector(state => state.admin);
  const role = useSelector(state => state.auth.role);

  const accessedPages = useMemo(() => pages.filter(t => !t.accessRoles || t.accessRoles.findIndex(t1 => t1 === role) !== -1), [role])

  const pagesList = useMemo(() => accessedPages.map(t => <Route exact path={t.link} key={t.link} component={() => <Page info={t} />} />),
    [accessedPages]);

  return <article className={b()}>
    <Navigation pages={accessedPages} />
    <Header />
    <div className={b('content')}>
      <Switch>
        <Redirect exact from ="/" to="/transfers" />        
        {pagesList}
      </Switch>
      <GridLoader loading={isProcess} color="#FF8D00" size={50} css={{ position: 'fixed', top: '50%', transform: 'translateY(-50%)' }}/>
      <Locale />
    </div>
  </article>;
}

export default Admin;