import React, { useCallback} from 'react';
import block from 'bem-cn';

import './Modal.scss';

const b = block('modal');

const Modal = ({ close = f => f, children = null }) => {
  const stop = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, [])

  return <div className={b('wrapper')} onClick={() => close()}>
    <article className={b()} onClick={stop}>
      {children}
    </article>
  </div>
}

export default Modal;