import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import block from 'bem-cn';

import Confirmation from 'components/Confirmation';
import Modal from 'components/Modal';

import './SpecificButton.scss';

const b = block('specific-button');

const SpecificButton = ({ settings, data = {} }) => {
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);

  const submit = useCallback(() => settings.func({ dispatch, data, callback: () => setOpen(false) }), [dispatch, settings, data])

  return isOpen ? <Modal close={() => setOpen(false)}>
    <Confirmation buttonText={settings.buttonTextId} callbackTrue={submit} callbackFalse={() => setOpen(false)} />
  </Modal> : <div className={b()} onClick={() => setOpen(true)}>
    +
  </div>
}

export default SpecificButton;