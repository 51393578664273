import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import { Link, useHistory } from 'react-router-dom';
import SVGInline from 'react-svg-inline';

import arrowSvg from './img/arrow.svg';

import './Navigation.scss';

const Navigation = ({ pages }) => {
  const b = block('navigation');
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const { locale } = useSelector(state => state.locale);
  const items = useMemo(() => pages.map(t => (
    <Link to={t.link} key={t.link} className={b('item', { active: history.location.pathname === t.link }) } onClick={() => setOpen(false)}>
      {locale[t.textIdent] || t.textIdent}
    </Link>
  )), [b, history.location.pathname, locale, pages])

  return <div className={b({ isOpen })} style={{ top: `-${pages.length * 2.5}rem` }}>
    <div className={b('items')}>
      {items}
    </div>
    <div className={b('button')} onClick={() => setOpen(!isOpen)} >
      <SVGInline svg={arrowSvg} className={b('arrow', { isOpen }).toString()} />
    </div>
  </div>
}

export default Navigation;