import dayjs from 'dayjs';

const funcionals = {
  lastMonth: buttonsFunctions => buttonsFunctions.setIntervalDates(dayjs().date(1).add(-1, 'month').format('YYYY-MM-DD'), dayjs().date(1).add(-1, 'day').format('YYYY-MM-DD')),
  thisMonth: buttonsFunctions => buttonsFunctions.setIntervalDates(dayjs().date(1).format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')),
  yesterday: buttonsFunctions => buttonsFunctions.setIntervalDates(dayjs().add(-1, 'day').format('YYYY-MM-DD'), dayjs().add(-1, 'day').format('YYYY-MM-DD')),
  today: buttonsFunctions => buttonsFunctions.setIntervalDates(dayjs().format('YYYY-MM-DD'), dayjs().format('YYYY-MM-DD')),
}

const hiloBetsButtons = {
  setLastMonth: {
    textIdent: 'lastMonth',
    func: funcionals.lastMonth,
  },
  setMonth: {
    textIdent: 'thisMonth',
    func: funcionals.thisMonth,
  },
  setYesteray: {
    textIdent: 'yesterday',
    func: funcionals.yesterday,
  },
  setDay: {
    textIdent: 'today',
    func: funcionals.today,
  },
}

const firecrashBetsButtons = {
  setLastMonth: {
    textIdent: 'lastMonth',
    func: funcionals.lastMonth,
  },
  setMonth: {
    textIdent: 'thisMonth',
    func: funcionals.thisMonth,
  },
  setYesteray: {
    textIdent: 'yesterday',
    func: funcionals.yesterday,
  },
  setDay: {
    textIdent: 'today',
    func: funcionals.today,
  },
}

const glassBridgeButtons = {
  setLastMonth: {
    textIdent: 'lastMonth',
    func: funcionals.lastMonth,
  },
  setMonth: {
    textIdent: 'thisMonth',
    func: funcionals.thisMonth,
  },
  setYesteray: {
    textIdent: 'yesterday',
    func: funcionals.yesterday,
  },
  setDay: {
    textIdent: 'today',
    func: funcionals.today,
  },
}
const transfersButtons = {
  setLastMonth: {
    textIdent: 'lastMonth',
    func: funcionals.lastMonth,
  },
  setMonth: {
    textIdent: 'thisMonth',
    func: funcionals.thisMonth,
  },
  setYesteray: {
    textIdent: 'yesterday',
    func: funcionals.yesterday,
  },
  setDay: {
    textIdent: 'today',
    func: funcionals.today,
  },
}

const spinButtons = {
  setLastMonth: {
    textIdent: 'lastMonth',
    func: funcionals.lastMonth,
  },
  setMonth: {
    textIdent: 'thisMonth',
    func: funcionals.thisMonth,
  },
  setYesteray: {
    textIdent: 'yesterday',
    func: funcionals.yesterday,
  },
  setDay: {
    textIdent: 'today',
    func: funcionals.today,
  },
}

export { hiloBetsButtons, firecrashBetsButtons, transfersButtons, spinButtons, glassBridgeButtons };