import {
  compose,
  applyMiddleware,
  combineReducers,
  createStore,
} from 'redux';
import thunk from 'redux-thunk';
import persistState from 'redux-localstorage';

import { reducer as localeReducer } from './features/Locale';
import { reducer as authReducer } from './features/Auth';
import { reducer as adminReducer } from './features/Admin';
import { reducer as notifyReducer } from './features/Notify';


function configureStore(extra) {
  const middlewares = [
    thunk.withExtraArgument({ ...extra }),
  ];

  const reducer = createReducer();

  const store = createStore(
    reducer,
    compose(
      applyMiddleware(...middlewares),
      persistState(['locale.lang', 'auth']),
      window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__({ trace: true }) : (arg => arg),
    ),
  );
  return store;
}

function createReducer() {
  return combineReducers({
    locale: localeReducer,
    auth: authReducer,
    admin: adminReducer,
    notify: notifyReducer,
  });
}


export { createReducer };
export default configureStore;
