import React, { useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';
import SVGInline from 'react-svg-inline';

import SwitchBox from 'components/SwitchBox';
import SpecificButton from 'components/SpecificButton';

import { inputTypes } from 'features/Admin/data/inputTypes';

import edit from './img/edit.svg';
import trash from './img/trash.svg';

import './TableRow.scss';

const TableRow = ({ data, titles, changedKeys, editFunc, removeFunc }) => {
  const b = block('table-row');
  const { locale } = useSelector(state => state.locale);
  const dispatch = useDispatch();

  const getValue = useCallback(title => {
    const item = data[title[0]];
    if (item === undefined) {
      return '';
    }
    switch (title[1].type) {
      case inputTypes.number: return item;
      case inputTypes.text: return item;
      case inputTypes.date: return dayjs(item).format('DD.MM HH:mm:ss');
      case inputTypes.select: return locale.selects[title[0]]?.[item];
      case inputTypes.json: return JSON.stringify(item);
      case inputTypes.bool: {
        if (changedKeys[title[0]] === undefined) {
          return item ? '+' : '-';
        } else {
          return <SwitchBox value={item} changeValue={() => dispatch(changedKeys[title[0]]?.(data))} />
        }
      }
      default: return item;
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, locale.selects])

  const items = Object.entries(titles).sort((a, b) => a[1].priority - b[1].priority).map((t, i, a) => 
  <div key={i} className={b('item')} style={{ width: `calc(100% / ${a.length})` }}>
    {t[1].specificButton?.validateFunc(data) ? 
      <SpecificButton settings={t[1].specificButton} data={data} />
     : getValue(t)}
  </div>)

  const editBlock = useMemo(() => editFunc ? <SVGInline className={b('edit').toString()} svg={edit} onClick={() => editFunc(data)} />
    : null, [editFunc, data, b]);

  const removeItem = useCallback(f => removeFunc ? dispatch(removeFunc(data)) : f, [dispatch, removeFunc, data])

  const removeBlock = useMemo(() => removeFunc ? <SVGInline className={b('edit').toString()} svg={trash} onClick={removeItem} />
    : null, [removeFunc, b, removeItem]);

  return <section className={b()}>
    {items}
    {(editBlock || removeBlock) && <div className={b('buttons-block')}>
      {editBlock}
      {removeBlock}
    </div>}
  </section>;
}

export default TableRow;