import React from 'react';
import block from 'bem-cn';

import './CheckBox.scss';

const b = block('check-box');

const CheckBox = ({ value, changeValue }) => <div className={b()} onClick={() => changeValue(!value)}>
  <div className={b('indicator', { active: value })} />
</div>

export default CheckBox;