
import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button';

import './Confirmation.scss';

const b = block('confirmation')

const Confirmation = ({ buttonText, callbackTrue, callbackFalse }) => {
  const locale = useSelector(state => state.locale.locale);

  return <div className={b()}>
    <div className={b('title')}>
      {locale.confirmation}
    </div>
    <div className={b('text')}>
      {locale.areYouSure}
    </div>
    <div className={b('buttons')}>
      <div className={b('button-container')}>
        <Button callBack={callbackTrue} text={locale[buttonText]} color="orange" size="container" isSquare  fontSize="small" />
      </div>
      <div className={b('button-container')}>
        <Button callBack={callbackFalse} text={locale.cancel} color="dark-fill" size="container" isSquare  fontSize="small" />
      </div>
    </div>
  </div>
}

export default Confirmation;