import success from '../img/success.svg';
import error from '../img/error.svg';


export const notificationImg = {
  success: {
    img: success,
    type: 'success',
  },
  error: {
    img: error,
    type: 'error',
  },
};