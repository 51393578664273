import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import block from 'bem-cn';

import Button from 'components/Button';

import './GlobalButtons.scss';

const b = block('global-buttons');

const GlobalButtons = ({ buttons }) => {
  const { locale } = useSelector(state => state.locale);
  const { isProcess } = useSelector(state => state.admin);
  const dispatch = useDispatch();

  const buttonsList = buttons.map(t => <div className={b('item')} key={t.textIdent}>
    <Button callBack={() => dispatch(t.func())} text={locale[t.textIdent]} color="dark-fill" size="container" isSquare isDisabled={isProcess} fontSize="small" />
  </div>)

  return <div className={b()}>
    {buttonsList}
  </div>
}

export default GlobalButtons;