import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';
import dayjs from 'dayjs';
import minMax from 'dayjs/plugin/minMax';
import CanvasJSReact from './canvasjs.react.js';

import './Graphic.scss';

const { CanvasJSChart } = CanvasJSReact;
dayjs.extend(minMax)

const b = block('graphic')

const Graphic = ({ data, conversionFialds }) => {
  const locale = useSelector(state => state.locale.locale);
  const platform = useSelector(state => state.auth.platform);

  const getAllDates = useCallback(() => Object.keys(data), [data])

  const options = useMemo(() => ({
    animationEnabled: true,
    backgroundColor: '#2A3040',
    height: platform === 'mobile' ? 400 : 600,
    title: {
      text: locale.conversion,
      fontColor: '#FFFFFF',
    },
    legend: {
      fontColor: '#FFFFFF',
      fontSize: 12,
    },
    axisX: {
      valueFormatString: 'DD.MM',
      titleFontColor: '#FFFFFF',
      lineColor: '#FFFFFF',
      labelFontColor: '#FFFFFF',
      tickColor: '#FFFFFF',
      gridDashType: 'solid',
      gridColor: '#1D212A',
      gridThickness: 1,
      labelFontSize: 12,
      titleFontFamily: 'Product Sans',
    },
    axisY: {
      title: locale.count,
      titleFontColor: '#FFFFFF',
      lineColor: '#FFFFFF',
      labelFontColor: '#FFFFFF',
      gridColor: '#1D212A',
      titleFontSize: 14,
      gridThickness: 1,
      labelFontSize: 12,
      titleFontFamily: 'Product Sans',
    },
    toolTip: {
      shared: true,
    },
    data: conversionFialds.map(field => ({
      markerType: 'circle',
        xValueFormatString: 'DD.MM',
        type: 'spline',
        name: locale[field.name] || '',
        color: field.color,
        showInLegend: true,
        dataPoints: getAllDates().map(t => {
          const value = data?.[t]?.[field.name] || 0;
          return { y: value, x: new Date(t) };
        }),
    }))
  
  }), [conversionFialds, data, getAllDates, locale, platform])

  return <div className={b()}>
    <CanvasJSChart options={options} />
  </div>
}

export default Graphic;