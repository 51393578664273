import React from 'react';
import { useSelector } from 'react-redux';
import block from 'bem-cn';

import './AdditionalInfo.scss';

const AdditionalInfo = ({ info }) => {
  const b = block('additional-info');
  const { locale } = useSelector(state => state.locale);
  const total_bet_by_currency = info.total_bet_by_currency ? <div className={b('currency-total')}>
    {`${locale.total_bet_by_currency}: ${Object.entries(info.total_bet_by_currency).reduce((str, t, i, array) => `${str} ${t[0]}: ${t[1]}${i === array.length - 1 ? '' : ' |'}`, '')}`}
  </div> : null
  const total_win_by_currency = info.total_win_by_currency ? <div className={b('currency-total')}>
    {`${locale.total_win_by_currency}: ${Object.entries(info.total_win_by_currency).reduce((str, t, i, array) => `${str} ${t[0]}: ${t[1]}${i === array.length - 1 ? '' : ' |'}`, '')}`}
  </div> : null
  const total_by_currency = info.total_by_currency ? <div className={b('currency-total')}>
  {`${locale.total_by_currency}: ${Object.entries(info.total_by_currency).reduce((str, t, i, array) => `${str} ${t[0]}: ${t[1]}${i === array.length - 1 ? '' : ' |'}`, '')}`}
</div> : null

  return <article className={b()}>
    {Object.entries({ ...info, total_bet_by_currency: undefined, total_win_by_currency: undefined, total_by_currency: undefined }).filter(t => t[1] !== undefined).map(t => <span key={t[0]} className={b('item')}>
      {`${locale[t[0]] || t[0]}: ${t[1]}`}
    </span>)}
    {total_bet_by_currency}
    {total_win_by_currency}
    {total_by_currency}
  </article>;
}

export default AdditionalInfo;