import React, { useState, useCallback } from 'react';
import { JsonEditor } from 'jsoneditor-react';
// eslint-disable-next-line no-unused-vars
import ace from 'brace';
import 'brace/mode/json';
import 'brace/theme/github';

import { useSelector } from 'react-redux';
import block from 'bem-cn';

import { inputTypes, getValueFromData } from 'features/Admin/data/inputTypes';
import Input from 'components/Input';
import Button from 'components/Button';

import './Edit.scss';

const b = block('edit');

const Edit = ({ element, save, close, titles, title }) => {
  const [data, changeData] = useState(element);
  const locale = useSelector(state => state.locale.locale);

  const stop = useCallback(e => {
    e.preventDefault();
    e.stopPropagation();
  }, [])

  const changeSpecificData = useCallback((key, value) => changeData({ ...data, [key]: value }), [data])

  const fields = Object.entries(titles).filter(t => t[1].isEditable).map(tempTitle => {
    const value = getValueFromData(data[tempTitle[0]], tempTitle[1].type)
    return <div key={tempTitle[0]} className={b('item')}>
      <div className={b('name')}>
        {locale[tempTitle[0]]}
      </div>
      {tempTitle[1].type === inputTypes.json ?
      <JsonEditor
        value={data[tempTitle[0]]}
        search={false}
        navigationBar={false}
        statusBar={false}
        mode="code"
        onChange={value => changeSpecificData(tempTitle[0], value)}
      /> : 
      <Input
        id={tempTitle[0]}
        type={tempTitle[1].type}
        value={value ?? ''}
        callBack={value => changeSpecificData(tempTitle[0], value)}
      />}
    </div>
  })

  const submit = useCallback(() => {
    let resultData = {};
    Object.entries(titles).filter(t => t[1].isEditable).map(tempTitle => resultData[tempTitle[0]] = data[tempTitle[0]]);
    save(resultData)
  }, [titles, save, data]);

  return <div className={b('wrapper')} onClick={close}>
    <article className={b()} onClick={stop}>
      <h2 className={b('title')}>{title}</h2>
      {fields}
      <div className={b('button-container')}>
        <Button callBack={submit} text={locale.save} color="dark-fill" size="container" isSquare  fontSize="small" />
      </div>
    </article>
  </div>;
}

export default Edit;