import { actions } from '../logic';
import { inputTypes } from './inputTypes';

const betsTitles = {
  game_id: {
    priority: 0,
    type: inputTypes.number,
  },
  remote_user_id: {
    priority: 1,
    isMain: true,
    type: inputTypes.number,
  },
  user_id: {
    priority: 2,
    type: inputTypes.number,
  },
  created_at: {
    priority: 3,
    isMain: true,
    type: inputTypes.date,
  },
  currency: {
    priority: 4,
    type: inputTypes.number,
  },
  transfer_id: {
    priority: 5,
    type: inputTypes.number,
  },
  bet_amount: {
    priority: 6,
    isMain: true,
    type: inputTypes.number,
  },
  win_amount: {
    priority: 7,
    isMain: true,
    type: inputTypes.number,
  },
  cards: {
    priority: 8,
    type: inputTypes.array,
    isMain: true,

  },
  picked_outcomes: {
    priority: 9,
    type: inputTypes.array,
    isMain: true,
  },
  req_id: {
    priority: 10,
    type: inputTypes.string,
    isMain: true,
  },
  win_transfer_id: {
    priority: 11,
    type: inputTypes.number,
  }
  // client_id: {
  //   priority: 1,
  //   type: inputTypes.number,
  // },
  // round_id: {
  //   priority: 2,
  //   isMain: true,
  //   type: inputTypes.number,
  // },
  // status: {
  //   priority: 9,
  //   type: inputTypes.select,
  // },
  // success_send: {
  //   priority: 10,
  //   type: inputTypes.bool,
  // },
}



const firecrashTitles = {
  id: {
    priority: 7,
    type: inputTypes.number,
  },
  game_id: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  transfer_id: {
    priority: 8,
    type: inputTypes.number,
  },
  bet_amount: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 6,
    type: inputTypes.number,
  },
  win_amount: {
    priority: 4,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  coefficient: {
    priority: 5,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  }
}

const plinkoTitles = {
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  game_id: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  balls_count: {
    priority: 2,
    type: inputTypes.number,
    isMain: true,
  },
  pyramid_height: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
  },
  coefficient: {
    priority: 4,
    type: inputTypes.number,
    isMain: true,
  },
  risk: {
    priority: 5,
    type: inputTypes.number,
    isMain: true,
  },
  bet_amount: {
    priority: 6,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  win_amount: {
    priority: 7,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 8,
    type: inputTypes.number,
  },
  transfer_id: {
    priority: 9,
    type: inputTypes.number,
  },
}

const kenoTitles = {
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  game_id: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  generated_cells: {
    priority: 2,
    type: inputTypes.number,
    isMain: true,
  },
  picked_cells: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
  },
  coefficient: {
    priority: 4,
    type: inputTypes.number,
    isMain: true,
  },
  bet_amount: {
    priority: 6,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  win_amount: {
    priority: 7,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 8,
    type: inputTypes.number,
  },
  transfer_id: {
    priority: 9,
    type: inputTypes.number,
  },
}

const diceTitles = {
  game_id: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  user_id: {
    priority: 2,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 3,
    type: inputTypes.number,
  },
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  bet_amount: {
    priority: 6,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  win_amount: {
    priority: 7,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  req_id: {
    priority: 8,
    type: inputTypes.number,
  },
  transfer_id: {
    priority: 9,
    type: inputTypes.number,
  },
  win_transfer_id: {
    priority: 10,
    type: inputTypes.number,
  },
  result_number: {
    priority: 11,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  coefficient: {
    priority: 12,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  chance_percent: {
    priority: 13,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  choice: {
    priority: 14,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  }

}

const glassBridgeTitles = {
  game_id: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  started_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  transfer_id: {
    priority: 8,
    type: inputTypes.number,
  },
  bet_amount: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 6,
    type: inputTypes.number,
  },
  win_amount: {
    priority: 4,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  pick: {
    priority: 6,
    type: inputTypes.string,
  },
  result_string: {
    priority: 7,
    type: inputTypes.string,
  },
}

const gamesTitles = {
  name: {
    priority: 1,
    isMain: true,
    isSort: true,
    type: inputTypes.string,
  },
  is_active: {
    priority: 2,
    isMain: true,
    type: inputTypes.bool,
  },
  // properties: {
  //   priority: 3,
  //   isEditable: true,
  //   type: inputTypes.json,
  // },
  // bet_limits: {
  //   priority: 4,
  //   isEditable: true,
  //   type: inputTypes.json,
  // },
  settings: {
    priority: 5,
    isEditable: true,
    type: inputTypes.json,
  },
}

const clientsTitles = {
  id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
  auth_token: {
    priority: 2,
    type: inputTypes.text,
    isEditable: true,
    specificButton: {
      buttonTextId: 'setToken',
      validateFunc: item => !item?.auth_token,
      func: ({ dispatch, data, callback }) => dispatch(actions.setToken({ data: data.id, callback })),
    }
  },
  company_name: {
    priority: 3,
    isCreatable: true,
    type: inputTypes.text,
    isEditable: true,
    isMain: true,
  },
  head_company_name: {
    priority: 4,
    isCreatable: true,
    type: inputTypes.string,
    isEditable: true,
    isMain: true,
  },
  seamless_url: {
    priority: 5,
    isCreatable: true,
    type: inputTypes.text,
    isEditable: true,
  },
  company_site: {
    priority: 6,
    isCreatable: true,
    type: inputTypes.text,
    isEditable: true,
    isMain: true,
  },
  secret_key: {
    priority: 10,
    isCreatable: true,
    type: inputTypes.text,
    isEditable: true,
  },
  url_ending: {
    priority: 11,
    isCreatable: true,
    type: inputTypes.text,
    isEditable: true,
  },
  currencies: {
    priority: 7,
    isCreatable: true,
    type: inputTypes.array,
    hidden: true,
  },
  login: {
    priority: 8,
    isCreatable: true,
    type: inputTypes.text,
    hidden: true,
  },
  password: {
    priority: 9,
    isCreatable: true,
    type: inputTypes.text,
    hidden: true,
  },
  royalty_coefficient: {
    priority: 12,
    type: inputTypes.number,
    isEditable: true,
    isCreatable: true,
    defaultValue: 0.08,
  }
}

const transfersTitles = {
  id: {
    priority: 7,
    type: inputTypes.number,
  },
  value: {
    priority: 0,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 1,
    type: inputTypes.string,
    isMain: true,
  },
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  type: {
    priority: 9,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  // user_id: {
  //   priority: 3,
  //   type: inputTypes.number,
  //   isMain: true,
  //   isForSort: true,
  // },
  remote_user_id: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  note: {
    priority: 10,
    type: inputTypes.string,
    isMain: true,
    isForSort: true,
  },
  req_id: {
    priority: 8,
    type: inputTypes.string,
  }, 
}

const spinTitles = {
  id: {
    priority: 7,
    type: inputTypes.number,
  },
  bet_amount: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  currency: {
    priority: 4,
    type: inputTypes.string,
    isMain: true,
  },
  created_at: {
    priority: -1,
    type: inputTypes.date,
    isMain: true,
    isForSort: true,
  },
  win_amount: {
    priority: 3,
    type: inputTypes.number,
    isMain: true,
    isForSort: true,
  },
  remote_user_id: {
    priority: 1,
    type: inputTypes.number,
    isMain: true,
  },
}

const currenciesTitles = {
  id: {
    priority: 0,
    type: inputTypes.number,
  },
  currency: {
    priority: 1,
    isCreatable: true,
    type: inputTypes.string,
  },
}

const currencyRatesTitles = {
  id: {
    priority: 0,
    type: inputTypes.number,
    hidden: true,
    isSort: true,
  },
  from_currency: {
    priority: 1,
    isCreatable: true,
    type: inputTypes.string,
  },
  to_currency: {
    priority: 2,
    isCreatable: true,
    type: inputTypes.string,
  },
  rate: {
    priority: 3,
    isCreatable: true,
    type: inputTypes.number,
    isEditable: true,
  },
}

const hashesTitles = {
  name: {
    priority: 1,
    type: inputTypes.text,
  },
  generate: {
    priority: 2,
    specificButton: {
      buttonTextId: 'ganerate',
      validateFunc: item => true,
      func: ({ dispatch, data, callback }) => dispatch(actions.genHashes({ data: data.name, callback })),
    }
  }
}

const clientGamesTitles = {
  name: {
    priority: 1,
    isMain: true,
    isSort: true,
    type: inputTypes.string,
  },
  is_active: {
    priority: 2,
    isMain: true,
    type: inputTypes.bool,
  },
  properties: {
    priority: 3,
    isEditable: true,
    type: inputTypes.json,
  },
  bet_limits: {
    priority: 4,
    isEditable: true,
    type: inputTypes.json,
  },
}

export {
  betsTitles,
  gamesTitles,
  firecrashTitles,
  clientsTitles,
  transfersTitles,
  currenciesTitles,
  spinTitles,
  currencyRatesTitles,
  hashesTitles,
  clientGamesTitles,
  glassBridgeTitles,
  plinkoTitles,
  kenoTitles,
  diceTitles,
}